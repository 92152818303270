<template>
  <div class="smart-wrap" v-if="layout != null && webBinds != null " >
    <div  :class="setClass(component)+' '+component.class" :style="styleComponent(component, 'wrap')" :id="component.id"  v-for="(component, index) in layout" :key="index" >
      
      <!-- Layout -->
      <div class="row" v-if="component.metaType == 'metaLayout' && component.elementType != 'repeatableCombo' && component.web == true" >
        <div :style="styleComponent(component, 'component')" class="row"  v-for="(bitem, bindex) in webBinds[component.attributeName]" :key="bindex">
          <paper-web-edit  
            v-if="bitem.SK != 'ADD' "
            :webPalette="palette" 
            :webIdioms="idioms" 
            :webLayoutCategory="'bind'" 
            :webLayout="component.smartComponents" 
            :webBinds="bitem" 
            v-on:click="setgRecord([ bitem ,'update',component.attributeName]);"
          />
          <div v-if=" bitem.SK == 'ADD' && (!bitem.GSP3SK1 || bitem.GSP3SK1 != 'Showcase') &&  component.metaJSON.dataSet.query.sortKeyBeginsWith == 'WEB'  "  :id="component.id"  v-on:click="setgRecord([ bitem, 'new', component.attributeName]);">
            <div :class="setClass(component)+' '+component.class+' addBlock'" :style="styleComponent(component, 'wrap')">
              <span v-if="bitem.group.attributeValue" style="color: #000!important; top: 20px; font-size: 22px; font-weight: 600; position: absolute; z-index: 999;" >
                {{ bitem.group.attributeValue }}
              </span>
              <span v-else style="color: #000!important; top: 20px; font-size: 22px; font-weight: 600; position: absolute; z-index: 999;" >
                {{ component.attributeName }}
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24"><path fill="#ccc" d="M19 12.998h-6v6h-2v-6H5v-2h6v-6h2v6h6z"/></svg>
            </div>
          </div>
          <div v-if=" bitem.SK == 'ADD' && component.metaJSON.dataSet.query.sortKeyBeginsWith != 'WEB'  "  :id="component.id"  v-on:click="setgRecord([ bitem, 'new',component.attributeName]);">
            <div :class="setClass(component)+' '+component.class+' addBlock'" :style="styleComponent(component, 'wrap')">
              <span style="color: #000!important; top: 20px; font-size: 22px; font-weight: 600; position: absolute; z-index: 999;" >
                {{ bitem.entityName }}
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24"><path fill="#ccc" d="M19 12.998h-6v6h-2v-6H5v-2h6v-6h2v6h6z"/></svg>
            </div>
          </div>
        </div>
      </div>
      <!-- Element  -->
      <div v-if="component.metaType == 'metaElement'"> 
        
        <!--span-- v-if="webLayoutCategory == 'bind'">
          {{component.attributeName}}
          <paper-displayer :value="webBinds"/>
        </!--span-->
        <div class="row" v-if="component.elementType == 'Collapse'">
          <h6 style="width:100%;" data-toggle="collapse" :href="'#collapse-'+component.no" role="button" aria-expanded="false" aria-controls="collapseExample" :style="styleComponent(component, 'component')">
            {{component.text[gLanguage]}}
          </h6>
          <div style="width:100%;" class="collapse" :id="'collapse-'+component.no">
            <paper-web-edit  :webPalette="palette" :webIdioms="idioms" :webLayout="component.smartComponents" :webBinds="webBinds" />
          </div>
        </div>
        <!--gLanguage-->
        <div v-if="component.elementType == 'language'" class="gLanguage"> 
          <span v-for="(idiom, yindex) in idioms" :key="yindex" :style="styleComponent(component, 'component')" :class="{ 'active': idiom == gLanguage, 'line': yindex+1 != idioms.length } " v-on:click="setLanguage(idiom)">{{srcIdioms[idiom]}}</span>
        </div>
        <!--amplify-s3-image :img-key="setValueFront(component, webBinds)" v-if="component.elementType == 'Img'"  :style="styleComponent(component, 'component')"/-->
        <img v-if="component.elementType.toLowerCase() == 'img'" :style="styleComponent(component, 'component')" :src="setValueFront(component, webBinds)"  data-toggle="modal"  :alt="setValueFront(component, webBinds)" srcset="">

        <ui-modal v-if="component.elementType.toLowerCase() == 'img'"  :name="'imgGallery'+webBinds.SK" type="sms"  >
          <template #content>
            <img style="width:100%;" :src="setValueFront(component, webBinds)" :alt="setValueFront(component, webBinds)" srcset="">
          </template>
        </ui-modal>
        <!--iframe-->
        <iframe v-if="component.elementType == 'iframe'" :src="component.url" frameborder="0" :style="styleComponent(component, 'component')"></iframe>
        <video v-if="component.elementType.toLowerCase() == 'video'"  :style="styleComponent(component, 'component')" :playsinline="component.playsinline" :autoplay="component.autoplay" :muted="component.muted" :loop="component.loop" :poster="component.poster" >
          <source :src="setValueFront(component, webBinds)" type="video/webm">
          <source :src="setValueFront(component, webBinds)" type="video/mp4">
        </video>
        <p v-if="component.elementType == 'Text' && component.class != 'dateFormat' " :id="id+'-'+index" :style="styleComponent(component, 'component')">{{setValueFront(component, webBinds, id+'-'+index)}}</p>
        <p v-if="component.elementType == 'Text' && component.class == 'dateFormat' " :style="styleComponent(component, 'component')">{{ setDate(setValueFront(component, webBinds))[0] }} <br>{{setDate(setValueFront(component, webBinds))[1]}}</p>
        <h5 v-if="component.elementType == 'Title'" :style="styleComponent(component, 'component')">{{setValueFront(component, webBinds)}}</h5>

        <paper-web-edit  :webPalette="palette" :webIdioms="idioms"  v-if="component.elementType == 'Div'" :webLayout="component.smartComponents" :webBinds="webBinds" :style="styleComponent(component, 'component')" />

        <a v-if="component.elementType == 'link'"  :target="component.target" rel="noopener noreferrer" v-on:click="actionLink(component)">

          <paper-web-edit  :webPalette="palette" :webIdioms="idioms"  :webLayout="component.smartComponents" :webBinds="webBinds" :style="styleComponent(component, 'component')" />

        </a>
      </div>
    </div>
  </div>
  <div v-else> 
    <!--p v-if style="padding: 20px;border: 1px dashed rgb(204, 204, 204);width: 900px;display: block;margin: 20px auto;text-align: center;color: #5f5f5f;font-weight: 600;     overflow: hidden;">  Layout structure not found <br> 
      <paper-displayer style="float:left; width:50%; display:block;" :title="'Layout'" class="" :value="layout"/>
      <paper-displayer style="float:left; width:50%; display:block;" :title="'webBinds'" :value="webBinds"/>
    </p--> 
  </div>
 
</template>
<script>
//AWS
import { smd_updateSmartRecord, smd_setItemState, smd_executeBackendFunctions, smd_addToCart, smd_updateSmartQuiz, smd_mutationOperation, smd_updateSmartAppointment,smd_updateSmartQuiz1 } from '../../graphql/mutations'
import { smd_getSMLItem, smd_getCart, insuranceTitle_operations, smd_queryOperation, smd_getCALItem } from '../../graphql/queries'
//Components
import PaperDisplayer from './PaperDisplayer.vue';
import UiButton from '../ui/UiButton.vue'
import UiCalendar from '../ui/UiCalendar.vue'
import UiModal from '../ui/UiModal.vue'
import UiCharts from '../ui/UiCharts.vue'
import UiTable from '../ui/UiTable.vue'
import UiRecursiveDisplay from '../ui/UiRecursiveDisplay.vue'
import PlEditor from '../../assets/plugins/PlEditor.vue'
import draggable from "vuedraggable";
//Tools
import { formatData, loading, checkInput, popUp, actionCalendar, decimalAdjust } from '../../store/tools.js';
import Swal from 'sweetalert2'
import { API, Storage,Auth } from "aws-amplify";
import Vuex from "vuex"; // Use a Variables Globals
import trumbowyg  from 'trumbowyg'
import 'trumbowyg/dist/ui/trumbowyg.css';
import { v4 as uuidv4 } from 'uuid';  


export default {
  name: "paper-web-edit",
  components:{
    draggable,
    PaperDisplayer,
    UiButton,
    UiCalendar,
    UiCharts,
    UiModal,
    PlEditor,
    UiRecursiveDisplay,
    UiTable
  },
  data() {
    return {
       responsive:'0',
      //TOTALS
      results:'',
      fileView: [],
      securityFile: 'public',
      descriptionFile: '',
      smName: "dfault",
      w_fileColumns: [{attributeName: "name", label:"Name"}], //Number of combos
      htmlTag : {tag:'none', help:'none'},
      totals : [] ,
      helpC: true,
      anchor: 0,
      searchSelect: '',
      test: '',
      //Bind Composer
      phone:{},
      currency:{},

      //Internal Table
      itemView: null,
      modelsInternalTable:[] ,
      layoutInternalTable:null,
      //Shopping

      //What
      modelsStr: [],

      //productParameters
      productParameters:{qty:1},

      phoneNumber:'',
      srcIdioms:{
        en:'English',
        es:'Spanish',
        it:'Italian',
        fr:'French',
        de:'German',
      },
      //Title
      titeInsurance:'',
      //Calendar
      calendarDays: ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'],
      calendarHours: [],
      mes:1,
      id:'',

      layout: undefined,
      paperPermissions : undefined,
      idioms : undefined,
      palette : undefined,
    }
  },
  props: {
    webLayout:{
      type: Array,
    },
    webBinds:{
      type: Object,
      default: null
    },
    webPaperPermissions:{
      type: Object,
      default:{
        usersCanEdit:[],
        usersCanRead:[],
        groupsCanEdit:["Admin","Superadmin","Editor"],
        groupsCanRead:["Admin","Superadmin","Editor"],  
      }
    },
    
    webIdioms:{
      type: Array,  
      default: ['en']
    },
    webPalette:{
      type: Array,  
      default: ['en']
    },
    webFilterSmarForm:{
      type: String,
      default: 'all'
    },
    webLayoutCategory:{
      type: String,
      default: 'form'
    }
  },
  created() {
      this.id =uuidv4()
      this.layout = this.webLayout
      this.paperPermissions = this.webPaperPermissions
      this.idioms = this.webIdioms
      this.palette = this.webPalette
    
  },
  
  mounted() {
  },
  methods: {
    //Global Methods
    ...Vuex.mapMutations(['setCustomWeb','setCalAlert','setGLanguage','setQuiz','setTargetFiled','setCartData',"SetOrganizationID","SetPageForm","SetPageTable","SetPageName","SetPageQuery", "SetPageMeta", "SetPageModule", "SetPageEntity", "setRecord", "setProductParameters", "setgRecord"]),
    ...Vuex.mapActions(["getRecord"]),
    //gLanguage



    setValueFront(component, webBinds, id){
      let data = component.elementType
      let gLanguage = this.gLanguage
      
      try {
        function checkValue(bind, component, att) {
          if (bind[component.attributeName]) {
            if (bind[component.attributeName].attributeValue ) {
              try {
              
                if (component.elementType.toLowerCase() == "img" || component.elementType.toLowerCase() == "video") {
                  if (typeof bind[component.attributeName].attributeValue == 'string') {
                    data = JSON.parse(bind[component.attributeName].attributeValue)[0].url.replace(/#/gi,'%23')
                  }else{
                    data = bind[component.attributeName].attributeValue[0].url.replace(/#/gi,'%23')
                  }
                }else{
                  data = bind[component.attributeName].attributeValue
                  if (data.startsWith('<') && component.elementType.toLowerCase() == 'text') {
                    setTimeout(() => {
                      document.getElementById(id).innerHTML = bind[component.attributeName].attributeValue
                    }, 500);
                  }
                }
              } catch (error) {
              
                data = component.url
              }
            }else{
              if (typeof bind[component.attributeName] == 'string') {
                data = bind[component.attributeName]
              }else{
                data = null
              }
            }
          }else{
            if (att == 'text') {
              try {
                data = component[att][gLanguage]
              } catch (error) {
                data = '_'+component[att]
              }
            }else{
              data = component[att]
            }
            //console.log(component[att]);
          }
        }
        if (webBinds.entityName == '') {
          
        }
        switch (component.elementType) {
          case 'Title':
            checkValue(webBinds, component, 'text'+[]);
          break;
          case 'Text':
            checkValue(webBinds, component, 'text');
          break;
          case 'Img':
            checkValue(webBinds, component, 'url');
          break;
          case 'img':
            checkValue(webBinds, component, 'url');
          break;
          case 'video':
            checkValue(webBinds, component, 'url');
          break;
          case 'shape':
            checkValue(webBinds, component, 'text');
          break;
          case 'link':
            switch (webBinds.shortEntity) {
              case 'BLOP':
                data = webBinds.path.attributeValue
              break;
              case 'PRO':
                data = webBinds.path.attributeValue
              break;
              default:
                data = component.url
              break;
            }
          break;
        }
        
      } catch (error) {
        data = 'loading...'
        console.log(error);
        console.log(webBinds);
        console.log(webBinds[component.attributeName]);
      }
      return data
    },
    setClass(component){
      let wrap = 'undefined'
      try {
        if (component.metaType) {
          wrap = component.metaType.replace('meta','').toLowerCase()+'-wrap'
          if (component.readOnly == true ||component.edit == false ||  this.webBinds[component.attributeName].complete == true) {
            //wrap = wrap + ' readOnly'
          }
          if (component.format != undefined && component.format != '' ) {
            wrap = wrap +" "+component.format
          }
          if (component.read == false) {
            wrap = 'displayNone'
          }
          if (component.smartInput == true) {
            wrap = wrap +" input-smart"
          }
        }
      } catch (error) {
        //console.log(component ,error);
      }
      return wrap
    },
    styleComponent(component, type){
      
      let style = ''
      let responsive = 0
      //srcFonts
      let font = ''
      let src = {}
        
      if (window.screen.width < 530) {
        responsive = 3
      } else if(window.screen.width < 767){
        responsive = 2
      } else if(window.screen.width < 992){
        responsive = 1
      }
      src.palette = this.palette
      try {
        if (typeof component.style.font.font == 'number') {
          font = this.srcFonts[component.style.font.font]
        }
        if (typeof component.style.font.font == 'string') {
          font = src.fonts[component.style.font.font]
        }
        font = src.fonts[component.style.font.font]
      } catch (error) {
        //console.log(error);
      }
      if (typeof component.style.font.font == 'string') {
        switch (component.style.font.font) {
          case 'Title': component.style.font.font = 0; break;
          case 'SubTitle': component.style.font.font = 1; break;
          case 'Text': component.style.font.font = 2; break;
          case 'SubText': component.style.font.font = 3; break;
        }
      }
      try {
        switch (type) {
          case 'wrap':
            try {
              style = 'width:'+ component.style.width[responsive]+'; height:'+ component.style.height[responsive]+'; transform:'+ component.style.transform +'; padding:'+ component.style.padding+'; margin: '+component.style.margin+';  background:' +src.palette[component.style.bgc]+'; border:'+ component.style.border.weight +' '+ component.style.border.type+' '+src.palette[component.style.border.color]+ ';'
            } catch (error) {  }
            style = style + ' '+ component.styleWrap
          break;  
          case 'component':
            try {
              style = 'width:'+ component.style.widthC[responsive]+'; height:auto; padding: 0; margin: 0; font-size:' +component.style.font.size[responsive]+'; font-family:'+this.webSite.fonts[component.style.font.font]+'; font-weight:' +component.style.font.weight+'; color:'+ src.palette[component.style.font.color]+';'
            } catch (error) { }
            style = style + ' '+ component.styleComponent
          break;  
        }
      } catch (error) {
        console.log(error);
        popUp(error, 'cach', 'err')
      }
      let palette = this.palette
      let regexp = /\[.+?\]/g;
      let bind = [...style.matchAll(regexp)];
      for (let index = 0; index < bind.length; index++) {
        let parameter = JSON.parse(bind[index][0])
        style = style.replace(bind[index][0], palette[parameter[1]])
      }
      return style

    },
  },
  computed: {
    ...Vuex.mapState(["organizationID","identityId", "pageForm", "pageTable", "pageName",'srcFonts', "pageQuery", "entity", "pageMeta", "pageModule", "permissions", "userPermissions", 'recordorganizationData', 'publicOrganization', 'cartData', 'organizationData','preloadLists','recordLists','products', 'gLanguage','webSite']),
    elementComputed:{
      get: function () {
        return 'gggg'
      },
      // setter
      set: function (newValue) {
        console.log(newValue);
      }
    },
    dragOptions() { 
      return {
        animation: 200,
        group: "inputs",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
}
</script>